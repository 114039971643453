<template>
<div>
  <div class="title-block blue">
    <div class="page-content">
      <h2>Prévention : IAP</h2>
      <strong>Informer, Accompagner, Prévenir</strong>
    </div>
  </div>
  <div class="activity-wrapper page-content">
    <div class="activity-description">
      <img :src="`${require('@/assets/logos/logo_iap.png')}`" alt="Logo IAP"  class="logo"/>
      <p><strong>IAP</strong> est un lieu ressource crée en 2000 autour de différents objectifs.</p>
      <p><strong>Informer sur les drogues</strong> et les risques liés à leurs usages, en direction de tous publics jeunes et adultes.</p>
      <p><strong>Élaborer et accompagner</strong> la création d’outils de prévention, dans une logique de prévention par les pairs. Consultation-conseil (accueillant adolescents et jeunes adultes consommateurs ou en situation à risques, ainsi que parents et professionnels confrontés à des problèmes liés à l’usage de drogues) devenue depuis Consultation Jeunes Consomateurs.</p>
      <p><strong>Sensibiliser et informer</strong> les acteurs sociaux. En transversalité de ces différentes missions, un travail en réseau est conduit avec l’ensemble des acteurs locaux, ce qui favorise :</p>
      <ul>
        <li>La construction d’une culture commune,</li>
        <li>La mise en oeuvre de programmes concertés de prévention des conduites addictives et d’autres conduites à risques,</li>
        <li>Le partage d’expériences autour des difficultés rencontrées et la proposition de réponses à des besoins de formation ou de réflexion sur les pratiques.</li>
      </ul>
    </div>
    <div class="activity-info">
      <strong>Nos champs d'intervention</strong>
      <ul>
        <li>Actions de prévention et d’information,</li>
        <li>Développement d’actions et création d’outils,</li>
        <li>Accompagnement de mesures de réparation,</li>
        <li>Consultation - conseil.</li>
      </ul>
      <br />
      <strong>Horaires</strong>
      <p>Accueil sur rendez-vous<br /> Lundi, mardi et jeudi de 9h30 à 17h <br/>Mercredi de 9h à 18h </p>
      <p><v-icon>{{ icons.mdiPhone }}</v-icon>01 43 53 04 36</p>
      <p><v-icon>{{ icons.mdiEmail }}</v-icon>iap-prevention@wanadoo.fr</p>

    </div>
  </div>

  <div class="title-block cyan">
    <div class="page-content">
      <h2>Consultation Jeunes Consommateurs</h2>
    </div>
  </div>
  <div style="background-color: white">
    <div class="page-content">
      <div class="activity-wrapper">

        <div class="activity-description">
          <img :src="`${require('@/assets/logos/cjc_logo.png')}`" alt="Logo CJC"  class="logo"/>
          <p>L’objectif de ces consultations est d’accueillir des jeunes consommateurs en questionnement sur leur consommation, ainsi que leur entourage. Le principe est de faire le point, éventuellement de proposer une aide, avant que la consommation ne devienne problématique.</p>
          <p>Toutes les problématiques d’addiction peuvent être abordées dans ce lieu : l’usage d’alcool, de cannabis, la pratique de jeux vidéo ou l’utilisation d’Internet.</p>
        </div>
        <div class="activity-info">
          <strong>Accueil</strong>
          <p>Accueil sur rendez-vous<br/>Lundi, mardi et jeudi de 9h30 à 17h<br/>Mercredi de 9h à 18h</p>
          <p>58 rue Gustave Eiffel , 94000 Créteil</p>
          <p><v-icon>{{ icons.mdiPhone }}</v-icon>01 43 53 04 36</p>
          <p><v-icon>{{ icons.mdiEmail }}</v-icon>iap-prevention@wanadoo.fr</p>
        </div>

      </div>
    </div>
  </div>


  <div class="title-block yellow">
    <div class="page-content">
      <h2>Formation</h2>
    </div>
  </div>
  <div style="background-color: white">
    <div class="page-content">
      <div class="activity-wrapper page-content">

        <div class="activity-description">
          <img :src="`${require('@/assets/logos/iap-formation.gif')}`" alt="Logo IAP Formation"  class="logo"/>
          <p>Drogues et Société a régulièrement développé, depuis sa création, des actions de formation participative en direction de professionnels n’ayant pas ou peu accès à la formation continue et cependant quotidiennement confrontés à des publics usagers de drogues (gardiens d'immeubles, employés de services municipaux, agents de sécurité…).</p>
          <p>Depuis 1999, deux axes de travail ont été particulièrement renforcés dans le cadre de deux programmes d’initiatives européennes pour l’emploi, Youthstart et Equal :</p>
          <ul>
            <li>la formation d'usagers dans une logique de prévention par les pairs,</li>
            <li>la formation de professionnels de proximité dans une perspective de renforcement de leurs compétences, de reconnaissance de nouvelles pratiques et de définition de nouvelles professionnalités.</li>
          </ul>
        </div>
        <div class="activity-info">
          <strong>Nos champs d'intervention</strong>
          <ul>
            <li>Stages de sensibilisation,</li>
            <li>Formation de professionnels,</li>
            <li>Accompagnement de projets.</li>
          </ul>
        </div>

      </div>
    </div>
  </div>


  <div class="title-block orange">
    <div class="page-content">
      <h2>Recherche Action</h2>
    </div>
  </div>
  <div class="activity-wrapper page-content">
    <div class="activity-description">
      <p>La recherche-action participative se fonde sur la concertation de l’ensemble des acteurs concernés par une problématique, une situation à évaluer et à transformer. Cette référence méthodologique favorise un questionnement permanent et collectif étayé par des allers et retours entre dynamiques de transformation et dynamiques de capitalisation des connaissances. Cette démarche est étayée de groupes de travail, d’expérimentations partagées et de temps de restitution donnant à voir mais aussi à « réajuster » la progression, dans une perspective à la fois de mutualisation de bonnes pratiques et de mise en réseau des acteurs et décideurs</p>
    </div>
    <div class="activity-info">
      <strong>Construction de réponses adaptées en :</strong>
      <ul>
        <li>Prévention,</li>
        <li>Accompagnement,</li>
        <li>Insertion.</li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
import {mdiEmail, mdiPhone} from "@mdi/js";

export default {
  name: "IAP",
  data() {
    return {
      icons: { mdiEmail, mdiPhone }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "services.scss";
</style>
