<template>
<div>
  <div class="title-block green">
    <div class="page-content">
      <h2>Accueil et Soins : CSAPA EPICE</h2>
    </div>
  </div>
  <div style="background-color: white">
    <div class="page-content">
      <div class="activity-wrapper">
        <div class="activity-description">
          <img :src="`${require('@/assets/logos/logo_epice.png')}`" alt="Logo EPICE"  class="logo"/>
          <p><strong>Un espace ouvert, d’accueil</strong> individuel et collectif recevant aujourd’hui environ 300 personnes chaque année.</p>
          <p>Cet espace, fortement ancré sur le territoire, s’inscrit dans une perspective de <strong>lutte contre l’exclusion</strong>, de citoyenneté active et de participation sociale <strong>des usagers de drogues</strong>.</p>
          <strong>Nos champs d'intervention</strong>
          <ul>
            <li>Accompagnement social et aide à l’insertion</li>
            <li>Soins et prise en charge médicale</li>
            <li>Suivi psychologique</li>
          </ul>
        </div>
        <div class="activity-info">

          <div>
            <strong>Qu'est-ce qu'un CSAPA ?</strong>
            <p>Centre de Soins, d'Accompagnement et de Prévention en Addictologie</p>

            <strong>Que signifie EPICE ?</strong>

            <p><strong>E</strong>space <strong>P</strong>arole <strong>I</strong>dentité <strong>C</strong>onnaissance <strong>E</strong>conomie</p>


            <strong>Accueil</strong>
            <p>Du lundi au vendredi de 9h à 12h45, sur rendez-vous l'après-midi</p>

            <p>42 rue Saint Simon, 94000 Créteil</p>

            <p><v-icon> {{ icons.mdiPhone }}</v-icon> 01.48.99.22.14</p>
            <p>01.48.99.57.44</p>
            <p><v-icon>{{ icons.mdiEmail}}</v-icon> epice94@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mdiEmail, mdiPhone} from '@mdi/js'
export default {
  name: "Epice",
  data() {
    return {
      icons: { mdiEmail, mdiPhone }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "services";
</style>
